import React, { useEffect, useState } from 'react'
import RedpocketLayout from '../../components/RedpocketLayout'
import { PageModelTitle } from '../../model/PageModel'
import { useIntl, Link } from 'gatsby-plugin-intl-v6'
import { postNewtonApi } from '../../utils/axiosData'
import { newtonApiUrl } from '../../utils/url'
import logo from "../../static/images/redpocket/logo.png"
import downloadZh from "../../static/images/redpocket/btn_downloadnewpay_zh.png"
import downloadEn from "../../static/images/redpocket/btn_downloadnewpay.png"
import congratulation from "../../static/images/redpocket/title_congratulations.png"

export default RedpocketDownload

function RedpocketDownload() {
  const intl = useIntl()
  let pageModel = new PageModelTitle(intl.formatMessage({ id: 'Redeem RedPocket' }), 'redpocket')
  return <div>{RedpocketLayout(Main(), pageModel)}</div>
}

function Main() {
  const intl = useIntl()
  const isZh = intl.locale === 'zh'

  const [giverName, setGiverName]: any = useState('')
  const [message, setMessage]: any = useState('')

  useEffect(() => {
    if (typeof window === "undefined") return;
    const searchParams = new URLSearchParams(window.location.search)
    let pocket_id = searchParams.get('id')
    if (pocket_id == null) {
      pocket_id = ''
    }
    
    const dataUrl = newtonApiUrl + 'dapp/redpocket/public/'
    const fetchData = async () => {
      const params = new URLSearchParams()
      params.append("pocket_id", pocket_id)
      const res = await postNewtonApi(dataUrl, params)
      console.log('fetchData res:', res)
      if (res && res.data && res.data.result) {
        setGiverName(res.data.result.giver_name)
        setMessage(res.data.result.pocket_message)
      }
    }
    fetchData()
  }, [])

  return (
    <>
      <div className="main">
        <div className="row">
          <span className="pull-left">
            <img src={logo} alt='logo' className="logo" />
          </span>
        </div>
        <p className="text-center">
          <img src={ congratulation } className="greeting" />
        </p>
        
        <p className="text-center tip">
        {intl.formatMessage({ id: "You've received " })}{ giverName }{intl.formatMessage({ id: "'s redpocket" })}
        </p>
        <div className="row download-content">
          <div className="message-container">
            <p className="text-center message">{ message }</p>
          </div>
        </div>
      
        <div className="download-footer">
          <p>{intl.formatMessage({ id: "* You have to download NewPay and use NEW wallet address to receive NEW." })}</p>
          <p>{intl.formatMessage({ id: "* If you have NewPay but it doesn't launch automatically, please save QR code photo first, and then use 'Scan-Album' on NewPay to open NEW red packet." })}</p>
          <p className="text-center">
            <Link to="/abwallet/">
              {isZh ? <img src={downloadZh} /> : <img src={downloadEn} /> }
            </Link>
          </p>
        </div>
      </div>
      
    </>
  )
}